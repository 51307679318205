import { Button, Input, MultipleSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { useEffect, useState } from 'react';
import { getToday } from 'utils';

const TableControls = ({ prevFilters = {}, onChange = () => {} }) => {
	const { translate } = useTranslations();

	const taskGroupService = new TaskGroupService();

	const fetchTaskGroups = async () => {
		taskGroupService.getOptionsList().then((res) => {
			setTaskGroupOptions(res.data);
		});
	};

	const today = getToday();
	const [taskGroupOptions, setTaskGroupOptions] = useState([]);
	const [actualFilters, setActualFilters] = useState({
		date: prevFilters.PlannedDate || today,
		statuses: prevFilters.Statuses || [],
		taskGroup: prevFilters.TaskGroupId || [],
	});

	const handleChange = (filters) => {
		setActualFilters(filters);
		onChange(filters);
	};

	useEffect(() => {
		fetchTaskGroups();
	}, []);

	const statusOptions = [
		{
			value: 1,
			label: translate('request'),
		},
		{
			value: 2,
			label: translate('assigned'),
		},
		{
			value: 3,
			label: translate('accepted'),
		},
		{
			value: 4,
			label: translate('inTransit'),
		},
		{
			value: 5,
			label: translate('completed'),
		},
		{
			value: 6,
			label: translate('cancelled'),
		},
		{
			value: 7,
			label: translate('provisional'),
		},
	];

	const onTodayClick = () => {
		handleChange({ ...actualFilters, date: today });
	};

	const onDateChange = (e) => {
		handleChange({ ...actualFilters, date: e.target.value });
	};

	const onNextDayClick = () => {
		const nextDay = new Date(actualFilters.date);
		nextDay.setDate(nextDay.getDate() + 1);

		handleChange({ ...actualFilters, date: nextDay.toISOString().split('T')[0] });
	};

	const onPrevDayClick = () => {
		const prevDay = new Date(actualFilters.date);
		prevDay.setDate(prevDay.getDate() - 1);

		handleChange({ ...actualFilters, date: prevDay.toISOString().split('T')[0] });
	};

	return (
		<div className='flex flex-col lg:flex-row pl-2 space-x-2 pb-3 items-center'>
			<div className='w-full lg:w-1/4 '>
				<Input type='date' onChange={(e) => onDateChange(e)} value={actualFilters.date} />
			</div>
			<div className='flex flex-row space-x-2 lg:space-x-0 my-2 lg-my-0'>
				<div>
					<Button onClick={() => onTodayClick()} color={'transparent'}>
						{translate('today')}
					</Button>
				</div>
				<div>
					<Button onClick={() => onPrevDayClick()} iconOnly={true} color={'transparent'}>
						<i className='ri-arrow-left-s-line'></i>
					</Button>
				</div>
				<div>
					<Button onClick={() => onNextDayClick()} iconOnly={true} color={'transparent'}>
						<i className='ri-arrow-right-s-line'></i>
					</Button>
				</div>
			</div>

			<div className='w-full lg:w-1/4 pt-3 lg:pt-0 '>
				<MultipleSelect
					containerClassName='-mt-5'
					label='statuses'
					noAllAtSubmit={true}
					hasAllOption={false}
					placeholder={translate('status')}
					options={statusOptions}
					defaultValue_={actualFilters.statuses}
					allItemsAreSelectedMessage='allStatusesAreSelected'
					onSelectOption={(option) => {
						handleChange({ ...actualFilters, statuses: option });
					}}
				/>
			</div>
			<div className='w-full lg:w-1/4 pt-7 lg:pt-0 '>
				<MultipleSelect
					containerClassName='-mt-5'
					label='taskGroups'
					name='taskGroupIds'
					options={taskGroupOptions}
					defaultValue_={taskGroupOptions.map((item) => item.value)}
					placeholder={translate('taskGroups')}
					allItemsAreSelectedMessage='allTaskGroupsAreSelected'
					onSelectOption={(option) => {
						handleChange({ ...actualFilters, taskGroup: option });
					}}
				/>
			</div>
		</div>
	);
};

export default TableControls;
