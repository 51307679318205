import React, { useState, useEffect } from "react";

import { Table } from "components/ui/Table";
import Card from "components/ui/Card";

import UsersForm from "../forms/user.form";
import UserSettings from "../forms/user.settings";

import { useTranslations, useCrud } from "hooks";

import userColumns from "../models/user.model";
import { impersonateUser } from "../utils";
import tokenService from "services/token/token.service";
import UserService from "../services";
import { CrudOperations } from "hoc";

const Tables = ({ permissions }) => {
	const service = new UserService();
	const { create, update, getAll, getExport } = useCrud(service);
	const { translate } = useTranslations();

	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");
	let userModel = userColumns;

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		fetchAndLoad,
		service,
		form: UsersForm,
		componentName: "user",
		permissions: permissions,
	});

	if (permissions.Impersonate && !tokenService.hasTemporaryAdmin()) {
		if (!userModel.find((x) => x.key === "impersonate"))
			userModel.push({
				key: "impersonate",
				label: "impersonate",
				componentRender: (d) => ImpersonateButton(d),
			});
	}

	const ImpersonateButton = (userId) => {
		return (
			<div className="flex justify-center items-center">
				<button
					title={translate("impersonate")}
					onClick={(e) => {
						e.stopPropagation();
						doImpersonate(userId);
					}}
					className="w-10 h-8 flex items-center justify-center rounded-lg border border-black hover:bg-black hover:bg-opacity-5 focus:ring-2 focus:ring-gray-400 active:bg-gray-400"
				>
					<i className="ri-spy-line"></i>
				</button>
			</div>
		);
	};

	const doImpersonate = async (userId) => {
		await impersonateUser(userId);
		window.location.reload();
	};

	return (
		<Card
			className="mt-2"
			collapsible
			defaultOpen={true}
			header={"users"}
			headerButtonLabel={
				permissions.Create && `+ ${translate("newUser")}`
			}
			headerButtonClick={openCreate}
		>
			<Table
				columns={userModel}
				onRowClick={(row) =>
					permissions.Update &&
					openEdit(row, { requestReload: fetchAndLoad })
				}
				onExportClick={async (queryString) => {
					return await getExport(queryString, "users");
				}}
				isExportable={permissions.Export}
				meta={data.meta}
				data={data.data?.map((f) => ({
					...f,
					employee_id: "",
					full_name: `${f.name} ${f.lastName}`,
					part_of_department: "",
					part_of_team: "",
					impersonate: f.id,
				}))}
				tableTitle="users"
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			/>
		</Card>
	);
};

export default Tables;
