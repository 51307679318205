import { useEffect, useState, useMemo, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { useUser, useOutsideClickHandler, useTranslations } from "hooks";
import { runAction } from "modules/utils";
import tokenService from "services/token/token.service";
import { PortalContext } from "contexts/PortalManager";
import { saveMySettings, selectProfile } from "modules/auth/services/auth";
import { toast } from "react-hot-toast";

const NavbarLink = ({ label, to, active = false }) => {
	const { translate } = useTranslations();
	return (
		<li>
			<Link
				to={to}
				className={`block py-2 pl-3 pr-4 text-center md:text-right bg-white rounded hover:bg-gray-100 sm:hover:bg-transparent md:bg-transparent md:p-0 ${
					active ? "font-bold text-slate-800" : "text-slate-500"
				}`}
			>
				{translate(label, true)}
			</Link>
		</li>
	);
};

const Navbar = ({ Logo, toggleSidebar, pages = [], externalMode = false }) => {
	const location = useLocation();
	const { translate, changeLanguage } = useTranslations();
	const { callAction } = useContext(PortalContext);

	const [toggle, setToggle] = useState(false);
	const [userDropdownOpen, setUserDropdownOpen] = useState(false);
	const [user, setUser] = useState(useUser());
	const [userProfiles, setUserProfiles] = useState(
		tokenService.getProfiles()
	);
	const [userProfilesOpen, setUserProfilesOpen] = useState(false);
	const [userSettings, setUserSettings] = useState(
		tokenService.getSettings()
	);

	const switchToProfile = async (profileId) => {
		const response = await selectProfile(
			profileId,
			tokenService.getLocalAccessToken()
		);
		if (response.succeeded) {
			const newProfiles = userProfiles?.map((profile) => {
				if (profile.id === profileId) {
					profile.isActive = true;
				} else {
					profile.isActive = false;
				}
				return profile;
			});
			tokenService.updateProfiles(newProfiles);
			setUserProfilesOpen(false);
			setUserProfiles(newProfiles);
			toast(translate("profileSwitched"), {
				duration: 2000,
				icon: "✅",
			});
			window.location.reload(); //maybe this should be removed
		} else {
			toast(translate("failedToSwitchProfile"), {
				duration: 2000,
				icon: "❌",
			});
		}
	};

	const saveUserSettings = async (settings = null) => {
		if (settings) {
			if (
				settings.language.toUpperCase() !==
				userSettings.language.toUpperCase()
			) {
				changeLanguage(settings?.language || "EN");
			}

			const response = await saveMySettings(
				settings,
				tokenService.getLocalAccessToken()
			);
			if (response.succeeded) {
				tokenService.updateAllSettings(settings);
				setUserSettings(settings);
			}
			return response;
		}
		return null;
	};

	const userTogleRef = useOutsideClickHandler(
		() => userDropdownOpen && setUserDropdownOpen(false)
	);

	const switchToAdmin = () => {
		tokenService.revertToAdmin();
		window.location.reload();
	};

	useEffect(() => {
		const handleProfileSwitch = () => {
			setUser(tokenService.getUser() || {});
		};

		window.addEventListener("profileSwitch", handleProfileSwitch);

		return () => {
			window.removeEventListener("profileSwitch", handleProfileSwitch);
		};
	}, []);

	const currentPage = useMemo(() => {
		let lastMatch = "";
		pages.forEach((page) => {
			if (location.pathname.indexOf(page.id) === 1) lastMatch = page.id;
		});
		return lastMatch;
	}, [pages, location]);

	return (
		<nav
			style={{ height: 54 }}
			className="bg-white border-gray-200 border-b flex flex-row"
		>
			<Link to="/" className="hidden sm:block p-2 pl-4">
				<img src={Logo} className="h-full w-auto" alt="Brand" />
			</Link>
			<div className="w-full flex flex-wrap items-center justify-between mx-auto p-3">
				<button
					className="visible sm:invisible px-1 text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
					onClick={() => toggleSidebar()}
				>
					<i className={`ri-side-bar-fill text-lg`} />
				</button>

				<button
					data-collapse-toggle="navbar-default"
					type="button"
					className="inline-flex items-center px-1 ml-3 text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
					aria-controls="navbar-default"
					aria-expanded="false"
					onClick={() => setToggle((t) => !t)}
				>
					<span className="sr-only">{translate("openMainMenu")}</span>
					<i className={`ri-menu-line text-lg`} />
				</button>
				<div
					className={`${
						toggle ? "" : "hidden"
					} w-full md:block md:w-auto z-10`}
					id="navbar-default"
				>
					<ul className="font-medium z-10 flex flex-col p-4 md:p-0 mt-4 border border-gray-200 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0 bg-white justify-center items-center">
						{tokenService.hasTemporaryAdmin() && (
							<li
								className="cursor-pointer text-red-700 "
								onClick={() => switchToAdmin()}
							>
								<i className="ri-spy-fill" />
								{translate("switchToAdmin")}
							</li>
						)}

						{pages.map((page) => (
							<NavbarLink
								key={page.id}
								to={page.to}
								label={page.label}
								active={currentPage === page.id}
							/>
						))}
						{/* TODO: This one should be a component */}
						{!externalMode && (
							<li ref={userTogleRef}>
								<div
									className="cursor-pointer flex flex-row justify-center mt-1 md:mt-0 items-center  font-medium bg-primary-800 text-white rounded-full w-8 h-8 "
									onClick={() =>
										setUserDropdownOpen((o) => !o)
									}
								>
									{user?.userName?.charAt(0).toUpperCase()}
									{/* <i className="ri-arrow-drop-down-fill text-3xl leading-[0rem]"></i> */}
								</div>

								<div
									id="dropdown"
									className={`z-20 ${
										userDropdownOpen ? "" : "hidden"
									} absolute bg-white divide-y divide-gray-500 rounded-lg shadow p-1`}
								>
									<div className="text-sm text-gray-700 absolute -right-7 p-2 bg-gray-50 rounded shadow-md w-72">
										<div
											onClick={() => {
												runAction(
													"auth",
													"openUserSettings",
													{
														callAction,
														data: userSettings,
														callback: async (
															settings
														) => {
															if (settings) {
																return await saveUserSettings(
																	settings
																);
															}
														},
													}
												);
											}}
											className="cursor-pointer absolute right-3 opacity-60 hover:opacity-100"
										>
											<i className="ri-settings-line text-lg"></i>
										</div>
										<div className="flex items-center space-x-4 p-2">
											<div className="bg-primary-800 text-white rounded-full w-12 h-12 flex items-center justify-center text-2xl">
												{user?.userName
													?.charAt(0)
													.toUpperCase()}
											</div>
											<div>
												<div className="text-lg">
													{user.name} {user.lastName}
												</div>
												<div className="text-gray-500 font-thin">
													{user.userName}
												</div>
												<div className="text-cyan-700 text-xs pt-1">
													{user.email}
												</div>
											</div>
										</div>

										<div>
											{userProfiles
												?.filter(
													(profile) =>
														profile.isActive
												)
												?.map((profile) => (
													<div
														onClick={() => {
															if (
																userProfiles?.filter(
																	(profile) =>
																		!profile.isActive
																)?.length > 0
															) {
																setUserProfilesOpen(
																	(o) => !o
																);
															}
														}}
														className="flex flex-row  cursor-pointer  items-center justify-center bg-gray-100 "
													>
														<div
															key={profile.id}
															className={`pt-1 pb-2 text-center hover:opacity-70 ${
																userProfilesOpen
																	? "opacity-20"
																	: ""
															}`}
														>
															<i className="ri-user-line text-lg"></i>{" "}
															<span className="font-semibold">
																{translate(
																	profile.name,
																	true
																)}
															</span>
														</div>
													</div>
												))}
										</div>

										<div className=" bg-white ">
											{userProfilesOpen && (
												<div>
													<div className="text-center text-xs text-gray-700 opacity-70 mt-2">
														<i class="ri-information-line"></i>{" "}
														{translate(
															"switchToAnotherProfile"
														)}
													</div>
													<div className="my-1  transition ease-linear duration-500 transform scale-100 opacity-100">
														{userProfiles
															?.filter(
																(profile) =>
																	!profile.isActive
															)
															?.map((profile) => (
																<div
																	className="cursor-pointer flex flex-row items-center justify-between hover:bg-gray-50 pl-3 group"
																	onClick={() => {
																		switchToProfile(
																			profile.id
																		);
																	}}
																>
																	<div className=" mb-2 flex flex-row items-center opacity-70 hover:opacity-95">
																		<div className="text-lg">
																			<i className="ri-user-follow-fill"></i>
																		</div>
																		<span className=" pl-1 font-semibold text-primary-500 ">
																			{translate(
																				profile.name,
																				true
																			)}
																		</span>
																	</div>
																	<div>
																		<i className="ri-check-double-line hidden group-hover:block "></i>
																	</div>
																</div>
															))}
													</div>
												</div>
											)}
										</div>

										<div className="border-t border-gray-200"></div>
										<div
											className="cursor-pointer px-4 py-2 text-center hover:bg-gray-300 rounded font-thin my-0 transition-colors duration-200"
											onClick={() =>
												runAction("auth", "logout")
											}
										>
											{translate("logout")}
										</div>
									</div>
								</div>
							</li>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
