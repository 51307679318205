import { useTranslations } from "hooks";
const DocumentationTable = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
	},
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Prefix",
		},
	},
	{
		key: "source",
		label: "type",
		componentRender: (source) => Source(source),
	},
];

const Source = (source) => {
	const { translate } = useTranslations();
	return source === 1 ? translate("freeText") : translate("externalLink");
};

export default DocumentationTable;
