import { CheckBox } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useState } from 'react';
import { Each } from 'utils/Each';
import ColumnItem from './ColumnItem';

export const ColumnsForm = ({ data, onColumnsChange = () => {} }) => {
	const { translate } = useTranslations();
	const defaultColumns =
		data?.columns?.map((column, i) => ({
			...column,
			hidden: column.hidden || false,
			order: i,
		})) || [];

	const [columns, setColumns] = useState(defaultColumns);

	const resetToDefault = () => {
		setColumns(defaultColumns);
		onColumnsChange(defaultColumns);
	};

	const moveUp = (index) => {
		if (index === 0) {
			return;
		}
		const item = columns[index];
		const newColumns = [...columns];
		newColumns.splice(index, 1);
		newColumns.splice(index - 1, 0, item);
		newColumns.forEach((item, index) => {
			item.order = index + 1;
		});
		updateColumns(newColumns);
	};

	const moveDown = (index) => {
		if (index === columns.length - 1) {
			return;
		}
		const item = columns[index];
		const newColumns = [...columns];
		newColumns.splice(index, 1);
		newColumns.splice(index + 1, 0, item);
		newColumns.forEach((item, index) => {
			item.order = index + 1;
		});
		updateColumns(newColumns);
	};

	const onCheckChange = (index, checked) => {
		const newColumns = [...columns];
		newColumns[index].hidden = !checked;
		updateColumns(newColumns);
	};

	const updateColumns = (columns) => {
		setColumns(columns);
		onColumnsChange(columns);
	};

	return (
		<div className='w-full h-100 pb-10 pl-5 overflow-y-visible'>
			<div>
				<div className='border-b -mt-2 flex justify-between items-center'>
					<CheckBox
						labelClass='text-lg font-bold'
						selected={columns.every((column) => !column.hidden)}
						halfChecked={
							columns.some((column) => !column.hidden) && columns.some((column) => column.hidden)
						}
						label='displayAllColumns'
						onChange={(e) => {
							const newColumns = columns.map((column) => ({
								...column,
								hidden: !e.target.checked,
							}));
							updateColumns(newColumns);
						}}
					/>
					{!columns.every((column, index) => {
						return (
							column.hidden === defaultColumns[index].hidden &&
							column.order === defaultColumns[index].order
						);
					}) && (
						<span
							onClick={() => resetToDefault()}
							className='ml-3 text-sm text-primary-300 font-medium cursor-pointer  hover:text-primary-500'
						>
							{translate('resetToDefault')}
						</span>
					)}
				</div>
			</div>
			<div className='pt-1'>
				<Each
					of={columns}
					render={(column, index) => {
						return (
							<ColumnItem
								column={column}
								index={index}
								onMoveUp={moveUp}
								onMoveDown={moveDown}
								onCheckChange={onCheckChange}
								moveUpDisabled={index === 0}
								moveDownDisabled={index === columns.length - 1}
							/>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default ColumnsForm;
