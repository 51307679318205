import { useState, useEffect, Fragment } from "react";
import toast from "react-hot-toast";
import { Table } from "components/ui/Table";
import Card from "components/ui/Card";
import {
	getAddressesForTable,
	getExport,
} from "modules/addresses/services/addresses";
import {
	addressFields,
	extraAddressFields,
} from "../../../models/address.model";
import { useTranslations } from "hooks";

const TableView = ({ permissions }) => {
	const { translate } = useTranslations();
	const [level, setLevel] = useState(6);
	const [data, setData] = useState({
		meta: {},
		data: [],
	});

	const columnsToShow = [
		...addressFields.slice(0, level),
		...extraAddressFields.filter((af) => af.meta.level <= level),
	];

	const loadAddresses = async (level, paginationAndFilter = "") => {
		const d = await getAddressesForTable(
			6 - level + 1,
			paginationAndFilter
		);
		console.log("ADDR LOAD", d);
		setData(d);
	};

	useEffect(() => {
		const load = async () => {};

		load();
	}, []);

	useEffect(() => {
		loadAddresses(level);
	}, [level]);

	useEffect(() => console.log("DATA CHANGE", data), [data]);

	const LevelToggles = ({ onLevelSelect = () => {} }) => {
		const Level = ({ children, onClick, selected = true }) => (
			<div
				onClick={onClick}
				className={`cursor-pointer px-2 py-1 border rounded border-slate-200 bg-white hover:bg-gray-100 ${
					selected ? "" : "opacity-50"
				}`}
			>
				{children}
			</div>
		);

		const Divider = ({ selected = true }) => (
			<div className="p-1">
				<i
					className={`ri-arrow-right-s-line ${
						selected ? "" : "opacity-50"
					}`}
				></i>
			</div>
		);

		const levels = ["group", "campus", "site", "zone", "room", "bed"];

		return (
			<div className="flex flex-row pb-2">
				{levels.map((l, i) => {
					return (
						<Fragment key={`addr-level-${l}`}>
							<Level
								onClick={() => onLevelSelect(i + 1)}
								selected={i < level}
							>
								{translate(l, true)}
							</Level>
							{i < levels.length - 1 && (
								<Divider selected={i < level - 1} />
							)}
						</Fragment>
					);
				})}
			</div>
		);
	};

	return (
		<Card
			className="mt-2"
			collapsible
			defaultOpen={true}
			header="locations"
		>
			<Table
				controls={
					<LevelToggles onLevelSelect={(level) => setLevel(level)} />
				}
				onExportClick={async (queryString) => {
					const exportData = await getExport(queryString);

					const blob = new Blob([exportData]);
					const url = URL.createObjectURL(blob);

					const link = document.createElement("a");
					link.href = url;
					link.download = `addresses-export-${Date.now()}.xlsx`;
					link.click();

					URL.revokeObjectURL(url);
				}}
				isExportable={permissions.Export}
				columns={columnsToShow}
				meta={data.meta}
				data={data.data?.map((f) => ({
					...f,
					employee_id: "",
					full_name: `${f.name} ${f.lastName}`,
					part_of_department: "",
					part_of_team: "",
				}))}
				onTableRequestChange={(queryString) =>
					loadAddresses(level, queryString)
				}
			/>
		</Card>
	);
};

export default TableView;
