import React, { useState, useEffect } from "react";
import TaskTypeService from "../services";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";
import { useTranslations, useCrud } from "hooks";
import { CrudOperations } from "hoc";
import taskTypeColumns from "../models/taskType.model";
import TaskTypeForm from "../forms/taskType.form";

const Tables = ({ taskGroupId, permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const service = new TaskTypeService();
	service.setParent(taskGroupId);

	const { getAll, getExport, create, update, remove } = useCrud(service);
	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null) => {
		setIsLoading(true);
		const res = await getAll(l_queryString || queryString);
		setIsLoading(false);
		setData(res);
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskTypeForm,
		componentName: "taskType",
		permissions: permissions,
	});

	useEffect(() => {
		fetchAndLoad();
	}, [taskGroupId]);

	return (
		<Card
			className="mt-2"
			collapsible
			defaultOpen={true}
			header={"taskTypes"}
			headerButtonLabel={
				permissions.Create && `+ ${translate("createTaskType")}`
			}
			headerButtonClick={openCreate}
		>
			<Table
				isLoading={isLoading}
				columns={taskTypeColumns}
				isExportable={permissions.Export}
				onExportClick={async (queryString) => {
					return await getExport(queryString, "taskTypes");
				}}
				onRowClick={(row) => permissions.Update && openEdit(row)}
				data={data?.data.map((item) => {
					return {
						...item,
					};
				})}
				meta={data.meta}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			/>
		</Card>
	);
};
export default Tables;
