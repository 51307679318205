import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { useAside, useCrud, useTranslations } from 'hooks';
import { useEffect, useRef, useState } from 'react';

import { CrudOperations } from 'hoc';
import { runAction } from 'modules/utils';
import { getToday } from 'utils';
import Controls from '../components/TableControls';
import TaskTemplatePicker from '../components/TaskTemplatePicker/TaskTemplatePicker';
import TaskForm from '../forms/task.form';
import taskColumns from '../models/task.model';
import TaskService from '../services';
import ActionRow from './ActionRow';
import MultipleActionRow from './ActionRow/MultipleActionRow';

const AUTO_REFRESH_INTERVAL = 20000;

const Tables = ({ permissions, onControlUpdate = () => {}, onRefresh = () => {} }) => {
	const [data, setData] = useState({ data: [], meta: {} });

	const { asideBuilder } = useAside();
	const [isLoading, setIsLoading] = useState(false);
	const [taskActions, setTaskActions] = useState([]);
	const [defaultFilters, setDefaultFilters] = useState({
		PlannedDate: getToday(),
		Statuses: [1, 2, 4, 7],
		TaskGroupId: undefined,
	});

	const pollHandler = useRef(null);
	const queryString = useRef('PlannedDate=' + getToday());
	const bodyFilters = useRef({});

	const service = new TaskService();
	const { create, update, search, remove, postExport } = useCrud(service);
	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null, l_bodyFilters = null) => {
		setIsLoading(true);
		let searchBody = l_bodyFilters ? l_bodyFilters : bodyFilters.current;

		search(l_queryString || queryString.current, searchBody).then((res) => {
			setIsLoading(false);
			setData(res);
		});
	};

	const getModules = async () => {
		try {
			const res = await runAction('taskModules', 'getTaskActions');

			setTaskActions(res);
		} catch (error) {
			console.error('Error on getting task actions ', error);
		}
	};

	const { openCreate, openEdit, openClone } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskForm,
		componentName: 'task',
		permissions: permissions,
		closeOnOutsideClick: false,
		hardSaveMessage: 'DuplicatedEntity',
		hardSaveVariable: 'createAnyWay',
		formWidth: 48,
	});

	const controlsUpdateHandler = (data) => {
		onControlUpdate(data);
		const keyMap = {
			date: 'PlannedDate',
			statuses: 'Statuses',
			taskGroup: 'TaskGroupIds',
		};

		const newDefaultValues = {};

		Object.keys(data).forEach((key) => {
			newDefaultValues[keyMap[key]] = data[key];
		});

		setDefaultFilters(newDefaultValues);
	};

	const openPickDialog = (l_data = null) => {
		asideBuilder.setTitle(translate('newTask'));
		asideBuilder.setComponent(TaskTemplatePicker);
		asideBuilder.setOpen(true);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentKey('pickDialog-new-' + new Date());
		asideBuilder.setComponentProps({
			data: {},
			pickTaskTemplate: (taskTemplate, taskGroupId, l_taskTemplateLabel) => {
				asideBuilder.setIsLoading(false);
				asideBuilder.setOpen(false);
				asideBuilder.build();
				openCreate({
					data: {
						taskGroupId,
						taskTemplateId: taskTemplate,
						taskTemplateLabel: l_taskTemplateLabel,
					},
				});
			},
		});
		asideBuilder.build();
	};

	useEffect(() => {
		// fetchAndLoad();
		getModules();
	}, []);

	const startPolling = () => {
		if (!pollHandler.current) {
			pollHandler.current = setInterval(() => {
				fetchAndLoad(queryString.current, bodyFilters.current);
				onRefresh();
			}, AUTO_REFRESH_INTERVAL);
		}
	};

	const stopPolling = () => {
		if (pollHandler.current) {
			clearInterval(pollHandler.current);
			pollHandler.current = null;
		}
	};

	useEffect(() => {
		startPolling();
		return () => {
			stopPolling();
		};
	}, [pollHandler]);

	const handlePoling = (status = true) => {
		if (status) {
			startPolling();
		} else {
			stopPolling();
		}
	};

	return (
		<Card
			className='mt-2'
			collapsible
			defaultOpen={true}
			header={'tasks'}
			headerButtonLabel={permissions.Create && `+ ${translate('addTask')}`}
			headerButtonClick={openPickDialog}
		>
			<Table
				controls={<Controls prevFilters={defaultFilters} onChange={(data) => controlsUpdateHandler(data)} />}
				defaultFilters={defaultFilters}
				isLoading={isLoading}
				columns={taskColumns}
				canRefresh
				onRefreshRequest={() => {
					fetchAndLoad(queryString.current, bodyFilters.current);
					onRefresh();
				}}
				isExportable={permissions.Export}
				onExportClick={async (qs) => {
					return await postExport(qs, {}, 'tasks');
				}}
				meta={data.meta}
				data={data.data?.map((item) => {
					return {
						...item,
					};
				})}
				hasRowActions={true}
				RowActionsComponent={(props) => (
					<ActionRow
						{...props}
						fetchAndLoad={fetchAndLoad}
						taskActions={taskActions}
						editTask={(item) => {
							permissions.Update &&
								openEdit({
									id: item.id,
									taskTemplateId: item.taskTemplate?.id,
									taskGroupId: item.taskGroup?.id,
									taskTemplateLabel: item.taskTemplate?.name,
									name: item.name,
								});
						}}
						cloneTask={(item) => {
							openClone({
								id: item.id,
								taskTemplateId: item.taskTemplate?.id,
								taskGroupId: item.taskGroup?.id,
								taskTemplateLabel: item.taskTemplate?.name,
								getServiceName: item.getServiceName,
								name: item.name,
							});
						}}
						modalsVisible={(s) => handlePoling(!s)}
					/>
				)}
				MultipleRowActionsComponent={(props) => (
					<MultipleActionRow {...props} taskActions={taskActions} fetchAndLoad={fetchAndLoad} />
				)}
				onTableRequestChange={(qs, postFilters) => {
					bodyFilters.current = postFilters;
					queryString.current = qs;
					fetchAndLoad(qs, postFilters);
				}}
			/>
		</Card>
	);
};
export default Tables;
