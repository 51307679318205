const RuleColumns = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
	},
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Name",
		},
	},
	{
		key: "enabled",
		label: "enabled",
		sortKey: "Enabled",
		componentRender: (value) => CheckedComponentRenderer(value),
	},
	{
		key: "description",
		label: "description",
		sortKey: "description",
		componentRender: (value) => TruncatedTextRenderer(value),
	},
];

const CheckedComponentRenderer = (value) => {
	return (
		<i
			className={`${
				value
					? "ri-checkbox-line text-2xl"
					: "ri-checkbox-blank-line text-2xl"
			}`}
		></i>
	);
};

const TruncatedTextRenderer = (str, num = 64) => {
	if (!str) return "";
	if (str?.length <= num) {
		return str;
	}
	return str?.slice(0, num) + "...";
};

export default RuleColumns;
