import { useState } from "react";

import { Button } from "../Input";
import { useTranslations } from "hooks";
import { type } from "@testing-library/user-event/dist/type";

const Card = ({
	children,
	className = "",
	noPadding = false,
	clickable = false,
	onClick = () => {},
	collapsible = false,
	defaultOpen = true,
	header = null,
	sideHeader = null,
	subHeader = null,
	headerButtonLabel = null,
	headerButtonClick = () => {},
	SecondaryComponent = null,
	isLoading = false,
}) => {
	const { translate } = useTranslations();
	const [open, setOpen] = useState(collapsible ? defaultOpen : false);

	return (
		<div
			className={` max-w ${
				clickable ? "cursor-pointer hover:border-sky-500" : ""
			} bg-white border border-gray-200 rounded-lg ${className}`}
			onClick={onClick}
		>
			<div
				className={`flex flex-row items-center cursor-pointer ${
					noPadding
						? ""
						: `${
								headerButtonLabel
									? "pt-4 pb-1 px-6"
									: "px-6 pt-6"
						  }`
				} ${open ? "pb-0" : "pb-5"}`}
				onClick={() => setOpen((o) => !o)}
			>
				<i
					className={`${
						open ? "ri-arrow-down-s-fill" : "ri-arrow-right-s-fill"
					} text-xl mr-2`}
				></i>
				{header && (
					<h1 className="font-bold">
						{typeof header === "string"
							? translate(header, true)
							: header}
					</h1>
				)}
				{sideHeader && (
					<h1 className="text-slate-400 ml-5">
						{typeof sideHeader === "string"
							? translate(sideHeader, true)
							: sideHeader}
					</h1>
				)}
				<div className="flex flex-1"></div>
				<div className="flex flex-row space-x-2">
					{SecondaryComponent && <SecondaryComponent />}
					{headerButtonLabel && (
						<div className="self-end">
							<Button
								onClick={(e) => {
									headerButtonClick();
									e.stopPropagation();
								}}
							>
								{headerButtonLabel}
							</Button>
						</div>
					)}
				</div>
			</div>

			{subHeader && (
				<div
					className={`${
						open ? "flex" : "hidden"
					} text-sm leading-4 -mt-2  pt-0 px-14 text-gray-500`}
				>
					{typeof subHeader === "string"
						? translate(subHeader, true)
						: subHeader}
				</div>
			)}

			{isLoading && (
				<div className="flex absolute left-1/2 transform -translate-x-1/2">
					<i className="ri-loader-4-line animate-spin text-2xl text-sky-500"></i>
				</div>
			)}

			<div
				className={` transition-all ease-soft-in duration-350 pt-0 px-6 ${
					open ? "pb-6 h-content" : "pb-0 h-0 overflow-hidden"
				} ${
					isLoading
						? "cursor-wait opacity-50 pointer-events-none"
						: "cursor-auto opacity-100"
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export default Card;
