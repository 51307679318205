export const addressFields = [
	{
		// 9
		key: "groupName",
		label: "group",
		sortKey: "group",
		filter: {
			type: "text",
			key: "group",
		},
	},
	{
		// 8
		key: "campusName",
		label: "campus",
		sortKey: "campus",
		filter: {
			type: "text",
			key: "campus",
		},
	},
	{
		// 7
		key: "siteName",
		label: "site",
		sortKey: "site",
		filter: {
			type: "text",
			key: "site",
		},
	},
	{
		// 6
		key: "zoneName",
		label: "zone",
		sortKey: "zone",
		filter: {
			type: "text",
			key: "zone",
		},
	},
	{
		// 5
		key: "roomName",
		label: "room",
		sortKey: "room",
		filter: {
			type: "text",
			key: "room",
		},
	},
	{
		// 2
		key: "bedName",
		label: "bed",
		sortKey: "bed",
		filter: {
			type: "text",
			key: "bed",
		},
	},
];

export const extraAddressFields = [
	{
		// 4
		key: "roomTypeName",
		label: "Room Type",
		sortKey: "roomTypeName",
		filter: {
			type: "text",
			key: "RoomType",
		},
		meta: {
			level: 5,
		},
	},
	{
		// 3
		key: "roomExternalId",
		label: "Room External Id",
		sortKey: "roomExternalId",
		filter: {
			type: "text",
			key: "roomExternalId",
		},
		meta: {
			level: 5,
		},
	},
	{
		// 1
		key: "bedExternalId",
		label: "Bed External Id",
		sortKey: "bedExternalId",
		filter: {
			type: "text",
			key: "bedExternalId",
		},
		meta: {
			level: 6,
		},
	},
];

export const addressFormFields = (
	translate,
	type = "",
	level = 0,
	siblings = []
) => {
	const formSchema = [
		{
			group: "GENERAL",
			inputs: [
				{
					component: "Input",
					type: "text",
					name: "name",
					label: `${type}`,
					placeholder: `${type}`,
					validations: {
						required: true,
					},
					validate: (f) => true,
					extract: (e) => e.target.value,
				},
			],
		},
	];

	if (level === 3 || level === 4) {
		formSchema.push({
			group: "distanceTiming",
			description: "thisFieldsAreUsedToCalculateTaskDurationAndDistance",
			inputs: siblings.map((sibling) => ({
				component: "Input",
				type: "number",
				name: `sibling:${sibling.id}`,
				label: translate("fromThis", false, sibling?.name),
				placeholder: ``,
				defaultValue: "0",
				sideLabel: "min",
				validations: {
					required: true,
					min: 0,
				},
				validate: (f) => true,
				extract: (e) => e.target.value,
				className: "text-right",
			})),
		});
	}

	if (level === 2) {
		formSchema[0].inputs.push({
			component: "ReSelect",
			name: "RoomTypeId",
			label: translate("roomType"),
			placeholder: translate("roomType"),
			propOptions: "roomTypes",
			validations: {
				required: true,
			},
			validate: (v) => {
				if (!v || v.trim() === "") {
					return "Select an Option!";
				}
				return true;
			},
			extract: (e) => e.target.value,
		});

		formSchema[0].inputs.push({
			component: "Input",
			name: "externalId",
			label: translate("roomExternalId"),
			placeholder: ``,
			validations: {
				required: false,
			},
			validate: (f) => true,
			extract: (e) => e.target.value,
		});
	}

	if (level === 1) {
		formSchema[0].inputs.push({
			component: "Input",
			name: "externalId",
			label: translate("bedExternalId"),
			placeholder: ``,
			validations: {
				required: false,
			},
			validate: (f) => true,
			extract: (e) => e.target.value,
		});
	}

	return formSchema;
};

export const mapWithDistances = (data) => {
	const parsedData = {};

	Object.entries(data).forEach(([key, value]) => {
		if (key.indexOf("sibling:") === 0) {
			if (!("distances" in parsedData)) {
				parsedData.distances = [];
			}
			parsedData.distances = [
				...parsedData.distances,
				{
					distance: parseInt(value, 10),
					siblingToId: parseInt(key.split(":")[1], 10),
				},
			];
		} else if (key === "roomType") {
			parsedData[key] = parseInt(value, 10);
		} else {
			parsedData[key] = value;
		}
	});

	return parsedData;
};
