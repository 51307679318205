const DefaultSettingColumns = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
	},
	{
		key: "taskGroup",
		label: "taskGroup",
		sortKey: "TaskGroup",
		componentRender: (taskGroup) => TaskGroupRenderer(taskGroup),
	},
	{
		key: "taskTypes",
		label: "taskTypes",
		sortKey: "TaskTypes",
		componentRender: (taskTypes) => TaskTypesRenderer(taskTypes),
	},
];

const TaskGroupRenderer = (taskGroup) => {
	return taskGroup?.name;
};

const TaskTypesRenderer = (taskTypes) => {
	const taskTypesString = taskTypes
		?.map((taskType) => taskType?.name)
		.join(", ");

	// Truncate the string to 64 characters
	return TruncatedTextRenderer(taskTypesString);
};

const TruncatedTextRenderer = (str, num = 64) => {
	if (!str) return "";
	if (str?.length <= num) {
		return str;
	}
	return str?.slice(0, num) + "...";
};

export default DefaultSettingColumns;
