import api from "services/api";
import tokenService from "services/token/token.service";

import tenant from "config/tenant";

const endpoint = "users";

export const loginExternalIntegration = async (data) => {
	return await api.post(`${endpoint}/login`, data, {
		headers: {
			TenantId: tenant.id,
		},
	});
};

export const login = async (username, password) => {
	return await api.post(
		`${endpoint}/login`,
		{
			username,
			password,
		},
		{
			headers: {
				TenantId: tenant.id,
			},
		}
	);
};

export const loginExternal = async (token, otherData = {}) => {
	return await api.post(
		`${endpoint}/external-login`,
		{ token, ...otherData },
		{
			headers: {
				TenantId: tenant.id,
			},
		}
	);
};

export const login2Fa = async (username, code) => {
	return await api.post(
		`${endpoint}/login-app-otp`,
		{
			username,
			code,
			recovery: false,
		},
		{
			headers: {
				TenantId: tenant.id,
			},
		}
	);
};

export const saveMySettings = async (settings, token) => {
	return await api.put(`${endpoint}/me/settings`, settings, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

/**
 * !IMPORTANT: Requires auth token
 */
export const getMyUser = async (token) => {
	return await api.get(`${endpoint}/me`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

/**
 * !IMPORTANT: Requires auth token
 */
export const getMyProfiles = async (token) => {
	return await api.get(`${endpoint}/me/profiles`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

/**
 * !IMPORTANT: Requires auth token
 */
export const selectProfile = async (id, token) => {
	return await api.put(
		`${endpoint}/me/profiles/${id}/activate`,
		{},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const logout = async () => {
	await api.post(`${endpoint}/me/logout`);
	tokenService.removeUser();
	window.dispatchEvent(new Event("login"));
};

export const requestRecoveryEmail = async (email) => {
	return api.get(`${endpoint}/forgot-password?username=${email}`);
};

export const resetPasswordWithCode = async (token, username, password) => {
	return await api.post(
		`${endpoint}/reset-password`,
		{
			username,
			token,
			password,
		},
		{
			headers: {
				TenantId: tenant.id,
			},
		}
	);
};
