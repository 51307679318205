import { useAside, useTranslations } from "hooks";
import ColumnsForm from "./columns.form";

const TableColumns = ({
	columns,
	tableTitle = "columnPicker",
	onColumnsChange = () => {},
}) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	const openColumnsPicker = () => {
		asideBuilder.setRight(false);
		asideBuilder.setOpen(true);
		asideBuilder.setTitle(translate(tableTitle));
		asideBuilder.setComponent(ColumnsForm);
		asideBuilder.setComponentKey(
			"columns-picker-" + tableTitle + "-" + new Date()
		);
		asideBuilder.setAutoClose(false);
		asideBuilder.setAutoClear(false);
		asideBuilder.setCloseOnOutsideClick(true);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentProps({
			data: {
				columns: columns,
			},
			onColumnsChange,
		});
		asideBuilder.build();
	};

	return {
		openColumnsPicker,
	};
};

export default TableColumns;
