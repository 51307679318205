import AbstractService from "services/Service";

const endpoint = "tenant";

class TenantService extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async mapData(reqData) {
		const { nationalHolidays, ...data } = reqData;
		const result = await super.mapData(data);

		if (nationalHolidays) {
			result.nationalHolidays = JSON.parse(nationalHolidays);
		}

		console.log("result", result);

		return result;
	}

	async create(data) {
		return await this.api.put(
			`${this.endpoint}/settings`,
			await this.mapData(data)
		);
	}

	async getSettings() {
		return await this.api.get(`${this.endpoint}/settings`);
	}

	async getReservedSettings() {
		return await this.api.get(`${this.endpoint}/reserved-settings`);
	}

	async putReservedSettings(data) {
		return await this.api.put(
			`${this.endpoint}/reserved-settings`,
			await this.mapData(data)
		);
	}

	async getDispatcherSettings() {
		return await this.api.get(`${this.endpoint}/dispatcher/settings`);
	}

	async putDispatcherSettings(data) {
		return await this.api.put(
			`${this.endpoint}/dispatcher/settings`,
			await this.mapData(data)
		);
	}

	async getEnum(key) {
		return await this.api.get(`${this.endpoint}/enums/${key}`);
	}

	async getConstants() {
		return await this.api.get(`${this.endpoint}/constants`);
	}

	async getVersion() {
		return await this.api.get(`${this.endpoint}/version`);
	}

	async getInputTypes() {
		return await this.api.get(`${this.endpoint}/custom-fields/input-types`);
	}
}
export default TenantService;
