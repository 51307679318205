import { withDashboardLayout } from "../../hoc";
import { Outlet } from "react-router-dom";

const CoursesModule = () => {
	return <Outlet />;
};

const config = {
	page: "persons",
	pages: [
		{ id: "persons/users", label: "users", to: "users" },
		{ id: "persons/patients", label: "patients", to: "patients" },
		{ id: "persons/visitors", label: "visitors", to: "visitors" },
	],
};

export default withDashboardLayout(CoursesModule, config);
