const complexActionsColumns = [
	{
		key: 'id',
		label: 'id',
		sortKey: 'Id',
	},
	{
		key: 'name',
		label: 'name',
		sortKey: 'Name',
		filter: {
			type: 'text',
			key: 'Prefix',
		},
	},
	{
		key: 'teams',
		label: 'teams',
		sortKey: 'Teams',
		componentRender: (teams) => TeamsRenderer(teams),
	},
	{
		key: 'action',
		label: 'action',
		sortKey: 'Action',
		componentRender: (action) => ActionRenderer(action),
	},
];
export default complexActionsColumns;

const TeamsRenderer = (teams) => {
	const taskTypesString = teams?.map((team) => team?.name).join(', ');

	return TruncatedTextRenderer(taskTypesString);
};

const TruncatedTextRenderer = (str, num = 64) => {
	if (!str) return '';
	if (str?.length <= num) {
		return str;
	}
	return str?.slice(0, num) + '...';
};

const ActionRenderer = (action) => {
	return action?.name;
};
