const OnlineEmployeesColumns = [
	{
		key: 'id',
		label: 'id',
		sortKey: 'Id',
	},
	{
		key: 'name',
		label: 'name',
		sortKey: 'Name',
		filter: {
			type: 'text',
			key: 'Name',
		},
	},
	{
		key: 'breakUntilOn',
		label: 'breakUntilOn',
		sortKey: 'breakUntilOn',
		componentRender: (value) => DateTimeRenderer(value),
	},
	{
		key: 'lastTaskCompletedOn',
		label: 'lastTaskCompletedOn',
		sortKey: 'lastTaskCompletedOn',
		componentRender: (value) => DateTimeRenderer(value),
	},
	{
		key: 'status',
		label: 'status',
		sortKey: 'status',
		componentRender: (value, row) => Status(value),
	},
	{
		key: 'teams',
		label: 'teams',
		sortKey: 'teams',
		componentRender: (value) => TeamsRenderer(value),
	},
];

const DateTimeRenderer = (value) => {
	if (!value) return 'N/A';
	const localTime = new Date(value).toLocaleString('en-GB', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});

	return localTime;
};

const Status = (value) => {
	if (!value) return '';
	const classes = [
		{
			bg: 'bg-cyan-100',
			circle: 'bg-cyan-500',
			text: 'text-cyan-700',
		},
		{
			bg: 'bg-red-100',
			circle: 'bg-red-500',
			text: 'text-red-700',
		},
		{
			bg: 'bg-purple-100',
			circle: 'bg-purple-500',
			text: 'text-purple-700',
		},
		{
			bg: 'bg-yellow-100',
			circle: 'bg-yellow-500',
			text: 'text-yellow-700',
		},
		{
			bg: 'bg-purple-100',
			circle: 'bg-purple-500',
			text: 'text-purple-700',
		},
	];

	const statusClass = classes[value?.id - 1] || classes[0];

	return (
		<div className='flex items-center'>
			<div className={`${statusClass.bg} px-3 py-1 rounded-xl flex-row flex items-center`}>
				<div className={`inline-block ${statusClass.circle} rounded-full h-3 w-3 mr-2`}></div>
				<div className={`uppercase ${statusClass.text}`}>{value?.name}</div>
			</div>
		</div>
	);
};

const TeamsRenderer = (values) => {
	return values.map((value, index) => {
		if (!value || !value.name) return null;
		const t_name = value.name.length > 12 ? value.name.substring(0, 12) + '...' : value.name;
		return (
			<div key={index} className='flex items-center'>
				<div
					className='px-3 py-1 pb-2 w-28 justify-center text-gray-900 whitespace-nowrap
					rounded-xl flex-row flex items-center'
					style={{ backgroundColor: value.color + '80' }}
				>
					{t_name}
				</div>
			</div>
		);
	});
};

export default OnlineEmployeesColumns;
