import React, { useState, useEffect, forwardRef, useMemo } from "react";
import TaskTemplateService from "../services";

import Card from "components/ui/Card";
import { Table } from "components/ui/Table";
import { useTranslations, useCrud } from "hooks";
import taskTemplateColumns from "../models/taskTemplate.model";
import { CrudOperations } from "hoc";
import TaskTemplateForm from "../forms/taskTemplate.form";

const Tables = ({ taskGroupId = -1, permissions }) => {
	const service = useMemo(() => new TaskTemplateService(), []);
	service.setParent(taskGroupId);

	const { create, update, getExport, remove, getAll } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetchAndLoad();
	}, [taskGroupId]);

	const fetchAndLoad = async (l_queryString = null) => {
		setIsLoading(true);
		const res = await getAll(l_queryString || queryString);
		setData(res);
		setIsLoading(false);
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskTemplateForm,
		componentName: "taskTemplate",
		permissions: permissions,
	});

	return (
		<Card
			className="mt-2"
			collapsible
			defaultOpen={true}
			header={"taskTemplates"}
			headerButtonLabel={
				permissions.Create && `+ ${translate("createTaskTemplate")}`
			}
			headerButtonClick={() =>
				openCreate({
					taskGroupId: taskGroupId,
					requestReload: fetchAndLoad,
				})
			}
		>
			<Table
				isLoading={isLoading}
				onRowClick={(row) =>
					permissions.Update &&
					openEdit(row, {
						taskGroupId: taskGroupId,
						requestReload: fetchAndLoad,
					})
				}
				columns={taskTemplateColumns}
				isExportable={permissions.Export}
				onExportClick={async (queryString) => {
					return await getExport(queryString, "taskTemplates");
				}}
				meta={data.meta}
				data={data.data?.map((item) => {
					return {
						...item,
						fields:
							item.fields.length > 0
								? item.fields
										.map((field) => field.name)
										.join(", ")
										.substring(0, 40) +
								  (item.fields
										.map((field) => field.name)
										.join(", ").length > 40
										? "..."
										: "")
								: "No fields",
					};
				})}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			></Table>
		</Card>
	);
};
export default Tables;
