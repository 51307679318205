import { useTranslations } from "hooks";
const TaskTypeTableModel = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
	},
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Prefix",
		},
	},
	{
		key: "advancedRequestTime",
		label: "advancedRequestTime",
		componentRender: (advancedRequestTime) => Minutes(advancedRequestTime),
	},
	{
		key: "expectedTaskTime",
		label: "expectedTaskTime",
		componentRender: (expectedTaskTime) => Minutes(expectedTaskTime),
	},
	{
		key: "anticipationTime",
		label: "anticipationTime",
		componentRender: (anticipationTime) => Minutes(anticipationTime),
	},
	{
		key: "enabled",
		label: "enabled",
		componentRender: (enabled) => Enabled(enabled),
	},
];
const Enabled = (enabled) => {
	const { translate } = useTranslations();
	return enabled ? (
		<div className="text-green-800">{translate("yes")}</div>
	) : (
		<div className="text-red-800">{translate("no")}</div>
	);
};

const Minutes = (minutes) => {
	const { translate } = useTranslations();
	if (!minutes) minutes = 0;
	return minutes + " " + translate("min");
};

export default TaskTypeTableModel;
