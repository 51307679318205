const ReturnTaskModel = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
		hidden: true,
	},
	{
		key: "from",
		label: "from",
		sortKey: "from",
		componentRender: (value) => AddressRenderer(value),
	},
	{
		key: "to",
		label: "to",
		sortKey: "to",
		componentRender: (value) => AddressRenderer(value),
	},
	{
		key: "scheduledTime",
		label: "scheduled",
		sortKey: "scheduledTime",
		componentRender: (value, row) => ScheduledDeparture(value, row),
	},
	{
		key: "taskType",
		label: "taskType",
		sortKey: "taskType",
		componentRender: (value) => TaskTypeRenderer(value),
		hidden: true,
	},
];

export default ReturnTaskModel;

const AddressRenderer = (value) => {
	// the address is a nested object and we have to display the previous than the last child and the list child like a breadcrumb

	let address = value?.child;
	const addressList = [];
	while (address) {
		addressList.push(address);
		address = address?.child;
	}

	//display only last 2 address like a breadcrumb
	if (addressList.length > 2) {
		const temp = addressList.slice(addressList.length - 2);
		addressList.length = 0;
		addressList.push(
			// eslint-disable-next-line no-undef
			...temp
		);
	}

	return (
		<div>
			{addressList.map((address, index) => (
				<div key={index} className="flex items-center">
					<span className="text-gray-500">{address.name}</span>
					{index !== addressList.length - 1 && (
						<span className="text-gray-500 mx-2">/</span>
					)}
				</div>
			))}
		</div>
	);
};

const ScheduledDeparture = (value, row) => {
	const localTime = new Date(value).toLocaleString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
	});

	const timeType = row?.scheduledTimeType?.name;

	return (
		<div>
			{/* we don't remember why we have this on design */}
			{/* <span className="inline-block bg-yellow-500 rounded-full h-2.5 w-2.5 mr-2"></span> */}
			{timeType && (
				<span className="pr-2">{timeType.charAt(0).toUpperCase()}</span>
			)}
			<span>{localTime}</span>
		</div>
	);
};
const TaskTypeRenderer = (value) => {
	return value?.name;
};
