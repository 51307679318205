const CustomFieldTableModel = [
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Prefix",
		},
	},
	{
		key: "inputType",
		label: "inputType",
		componentRender: (inputType) => inputType?.name,
	},
	{
		key: "value",
		label: "fieldValue",
		componentRender: (value) => ValueRender(value),
	},
	{
		key: "taskGroups",
		label: "availableForTaskGroups",
		componentRender: (taskGroups) =>
			availableForTaskGroupsRender(taskGroups),
	},
];
const ValueRender = (jsonValue) => {
	if (jsonValue) {
		const value = JSON.parse(jsonValue);
		if (value === null) {
			return "";
		}
		if (Array.isArray(value)) {
			return trimString(value.join(", "));
		} else {
			return trimString(value);
		}
	}
	return "";
};

const availableForTaskGroupsRender = (taskGroups) => {
	if (taskGroups) {
		return trimString(
			taskGroups.map((taskGroup) => taskGroup.name).join(", ")
		);
	}
};

function trimString(str) {
	const maxLength = 28;
	if (str.length > maxLength) {
		return str.substring(0, maxLength - 3) + "...";
	} else {
		return str;
	}
}

export default CustomFieldTableModel;
