import React, { useState, useEffect, forwardRef } from "react";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";

import permissionGroupsColumns from "../models/permissionGroup.model";

import PermissionGroupForm from "../forms/permissionGroup.form";
import { useTranslations, useCrud } from "hooks";
import PermissionGroupService from "../services";
import { CrudOperations } from "hoc";

const Tables = ({ permissions }) => {
	const { translate } = useTranslations();
	const service = new PermissionGroupService();
	const { getAll, create, update, remove } = useCrud(service);
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: PermissionGroupForm,
		componentName: "permissionGroup",
		permissions: permissions,
		formWidth: 54,
	});

	return (
		<Card
			className="mt-2"
			collapsible
			defaultOpen={true}
			header={"permissionGroups"}
			headerButtonLabel={
				permissions.Create && `+ ${translate("newGroup")}`
			}
			headerButtonClick={openCreate}
		>
			<Table
				isExportable={false}
				columns={permissionGroupsColumns}
				meta={data.meta}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
				onRowClick={(row) => permissions.Update && openEdit(row)}
				data={data.data.map((item) => {
					return {
						...item,
					};
				})}
			/>
		</Card>
	);
};

export default Tables;
