const UrgencyTableColumns = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
	},
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Prefix",
		},
	},
	{
		key: "priority",
		label: "priority",
		sortKey: "Priority",
	},
	{
		key: "label",
		label: "label",
		sortKey: "Label",
	},
];

export default UrgencyTableColumns;
