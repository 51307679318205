import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { Input, MultipleSelect, Select, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useTranslations } from 'hooks';
import PermissionService from 'modules/persons/pages/Users/pages/Settings/pages/PermissionGroups/services';
import DepartmentService from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Departments/services';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates/pages/TaskTemplates/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { runAction } from 'modules/utils';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Permission from '../components/Permission';

const UserProfileForm = forwardRef((props, ref) => {
	const { asideBuilder } = useAside();
	const { translate } = useTranslations();
	const { getOne } = useCrud(props.service);

	const viewPatientsLevelEnum = props.viewPatientsLevelEnum;
	const [data, setData] = useState([]);
	const [isFormDirty, setIsFormDirty] = useState(false);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const [permissionLoading, setPermissionsLoading] = useState(true);
	const [allPermissions, setAllPermissions] = useState([]);
	const [taskGroupOptions, setTaskGroupOptions] = useState([]);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const [permissionGroupOptions, setPermissionGroupOptions] = useState([]);
	const [selectedPermissions, setSelectedPermissions] = useState(null);
	const [taskTemplateOptions, setTaskTemplateOptions] = useState([]);

	const departmentService = new DepartmentService();
	const permissionService = new PermissionService();
	const taskGroupService = new TaskGroupService();
	const taskTemplateService = new TaskTemplateService();

	const fetchPermissions = async () => {
		const res = await runAction('permissions', 'getPermissions');
		setAllPermissions(res);
		return res;
	};

	const fetchTaskTemplates = (taskGroups = []) => {
		let taskGroupIds = [];
		if (taskGroups && taskGroups.some((item) => item === 1)) {
			const options = taskGroupOptions?.map((item) => item.value) ?? [];
			taskGroupIds = options.filter((item) => item !== 1);
		} else {
			taskGroupIds = taskGroups?.map((item) => item) ?? [];
		}

		taskTemplateService.getMultiParentOptionsList(taskGroupIds).then((res) => {
			setTaskTemplateOptions(res);
		});
	};

	const getAllPermissionTypes = (l_allPermissions) => {
		let l_permissions = {};
		Object.keys(l_allPermissions).forEach((permissionKey) => {
			Object.keys(l_allPermissions[permissionKey]).forEach((permissionItemKey) => {
				l_permissions[permissionItemKey] = 0;
			});
		});

		return l_permissions;
	};

	const getPermissionsOfPermissionGroups = async (l_permissionGroups) => {
		if (l_permissionGroups?.length > 0) {
			setPermissionsLoading(true);
			const l_allPermissions = await fetchPermissions();

			permissionService.getMultiple(l_permissionGroups).then((res) => {
				let updatedPermissions = {};
				res?.data?.forEach((permissionGroup) => {
					const newPermissions = permissionGroup.permissions;

					for (let key in getAllPermissionTypes(l_allPermissions)) {
						const newPermissionValue = newPermissions[key] ? newPermissions[key] : 0;
						const selectedPermissionValue = updatedPermissions[key] ? updatedPermissions[key] : 0;

						updatedPermissions[key] = selectedPermissionValue | newPermissionValue;
					}
				});

				setSelectedPermissions(updatedPermissions);
				setPermissionsLoading(false);
			});
		} else {
			setPermissionsLoading(false);
		}
	};

	const changePermissionGroups = (l_permissionGroups) => {
		getPermissionsOfPermissionGroups(l_permissionGroups);
	};

	const initiateData = () => {
		setLoading(true);

		taskGroupService.getOptionsList().then((res) => {
			setTaskGroupOptions(res.data);
		});

		departmentService.getOptionsList().then((res) => {
			setDepartmentOptions(res.data);
		});

		permissionService.getOptionsList().then((res) => {
			setPermissionGroupOptions(res.data);
		});

		fetchPermissions().then((res) => {
			setSelectedPermissions(getAllPermissionTypes(res));
		});

		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);

				fetchTaskTemplates(res?.taskGroupIds || []);
				getPermissionsOfPermissionGroups(res?.permissionGroupIds);
			});
		} else {
			setLoading(false);
			setPermissionsLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
		setSelectedPermissions(getAllPermissionTypes(allPermissions));
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	const closeAside = () => {
		asideBuilder.setOpen(false);
		asideBuilder.reset();
	};

	const handleLinkClick = (e) => {
		if (isFormDirty) {
			e.preventDefault();
			const userConfirmed = window.confirm(translate('youHaveUnsavedChangesAreYouSureYouWantToLeave'));
			if (userConfirmed) {
				closeAside();
				window.location.href = e.target.href;
			}
		} else closeAside();
	};

	const handleInputChange = (val, prevVal = null) => {
		//TODO: Optimize this part
		if (Array.isArray(val) || Array.isArray(prevVal)) {
			if (Array.isArray(val) && Array.isArray(prevVal)) {
				if (val.length === prevVal.length && val.every((v, i) => v === prevVal[i])) {
					return;
				}
			}
			if (prevVal === null) {
				return false;
			}
			if (Array.isArray(prevVal)) {
				if (prevVal.length === 1 && prevVal[0] === 1) {
					return;
				}
			}

			if (Array.isArray(val)) {
				if (val.length === 0) {
					return false;
				}
			}

			setIsFormDirty(true);
		}

		if (!isFormDirty && val !== prevVal) {
			setIsFormDirty(true);
		}
	};

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-auto'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />

					<InternalFormDivider>{translate('generalInformation')}</InternalFormDivider>
					<FormEntry label={'profileName'} required>
						<Input
							maxLength={100}
							placeholder={'profileName'}
							type='text'
							required={true}
							name='name'
							onChange={(e) => {
								handleInputChange(e.target.value, data?.name);
							}}
							defaultValue={data?.name || ''}
						/>
					</FormEntry>
					<FormEntry label={'description'}>
						<TextArea
							maxLength={500}
							rows={4}
							placeholder={'description'}
							required={false}
							name='description'
							onChange={(e) => {
								handleInputChange(e.target.value, data?.description);
							}}
							defaultValue={data?.description || ''}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='taskGroups'>
						<MultipleSelect
							name='taskGroupIds'
							options={taskGroupOptions}
							defaultValue={data?.taskGroupIds}
							onSelectOption={(selected) => {
								fetchTaskTemplates(selected);
								handleInputChange(selected, data?.taskGroupIds);
							}}
						/>
					</FormEntry>
					<FormEntry label='taskTemplates'>
						<MultipleSelect
							name='taskTemplateIds'
							options={taskTemplateOptions}
							defaultValue={data?.taskTemplateIds}
							onSelectOption={(selected) => {
								handleInputChange(selected, data?.taskTemplateIds);
							}}
						/>
					</FormEntry>

					<FormEntry
						label='partOfDepartment'
						helpText={
							<div align='right' className='pb-2 text-xs'>
								{translate('departmentsAreManagedIn')} <br />
								<Link
									onClick={handleLinkClick}
									className='text-cyan-500 font-medium'
									to='/persons/users/teams'
								>
									{' '}
									{translate('teamsAndDepartments')}
								</Link>
							</div>
						}
					>
						<MultipleSelect
							name='departmentIds'
							options={departmentOptions}
							defaultValue={data?.departmentIds}
							onSelectOption={(selected) => {
								handleInputChange(selected, data?.departmentIds);
							}}
						/>
					</FormEntry>

					<FormEntry helpText={<div className='pb-2'></div>} label='showPatients'>
						<Select
							name='viewPatientsLevel'
							options={Object.keys(viewPatientsLevelEnum).map((key) => ({
								value: viewPatientsLevelEnum[key],
								label: translate(key, true),
							}))}
							selectedValue={data?.viewPatientsLevel || null}
						/>
					</FormEntry>

					<FormEntry
						label='permissionGroups'
						helpText={
							<div align='right' className='pt-1 text-xs'>
								{translate('groupsAreManagedIn')} <br />
								<Link
									onClick={handleLinkClick}
									className='text-cyan-500 font-medium'
									to='/persons/users/settings#permission-groups'
								>
									{' '}
									{translate('settings')}
								</Link>
							</div>
						}
					>
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={false}
							name='permissionGroupIds'
							options={permissionGroupOptions}
							defaultValue={data?.permissionGroupIds}
							onSelectOption={(selected) => {
								changePermissionGroups(selected);
								handleInputChange(selected, data?.permissionGroup);
							}}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('permissions')}</InternalFormDivider>
					{permissionLoading && (
						<tr>
							<td
								className={`${'absolute top-10 left-0 w-full h-full z-50 overflow-y-hidden'}`}
								colSpan={2}
							>
								<div className='flex justify-center items-center h-32'>
									<i className='ri-loader-2-line animate-spin text-primary-500 text-3xl'></i>
								</div>
							</td>
						</tr>
					)}

					<tr className={`${permissionLoading ? 'cursor-wait opacity-50' : 'cursor-auto opacity-100'}`}>
						<td colSpan={2}>
							{
								//permission is object, iterate through it

								Object.keys(allPermissions).map((allPermissionKey, allPermissionIndex) => {
									const currentPermissions = allPermissions[allPermissionKey];

									return (
										<div>
											<div className='flex items-center text-md text-center font-semibold text-cyan-700 uppercase pt-8 pb-4'>
												<div className='flex-grow border-t border-cyan-500'></div>
												<span className='px-5 text-lg'>{translate(allPermissionKey)}</span>
												<div className='flex-grow border-t border-cyan-500'></div>
											</div>
											<div>
												{Object.keys(currentPermissions).map((key, index) => (
													<Permission
														onFormDirty={() => setIsFormDirty(true)}
														permissionKey={key}
														key={index}
														index={index}
														disabledAndCheckedPermissions={selectedPermissions[key]}
														activePermissions={data?.customPermissions?.[key] || 0}
														permissions={currentPermissions}
													/>
												))}
											</div>
										</div>
									);
								})
							}
						</td>
					</tr>
				</Form>
			</div>
		</Suspense>
	);
});
export default UserProfileForm;
