import { useTranslations } from "hooks";
const UserTable = [
	{
		key: "id",
		label: "employeeId",
		sortKey: "id",
	},
	{
		key: "name",
		label: "name",
		sortKey: "name",
		filter: {
			type: "text",
			key: "name",
		},
	},
	{
		key: "lastName",
		label: "surname",
		sortKey: "lastName",
		filter: {
			type: "text",
			key: "lastName",
		},
	},
	{
		key: "email",
		label: "email",
		sortKey: "email",
		filter: {
			type: "text",
			key: "email",
		},
	},
	{
		key: "userName",
		label: "username",
		filter: {
			type: "text",
			key: "userName",
		},
	},
	{
		key: "profiles",
		label: "userProfiles",
		componentRender: (profiles) => UserProfile(profiles),
	},
	{
		key: "lockoutEnabled",
		label: "status",
		componentRender: (lockoutEnabled) => LockoutStatus(lockoutEnabled),
		filter: {
			type: "select",
			key: "lockoutEnabled",
			options: [
				{
					label: "all",
					value: "",
				},
				{
					label: "active",
					value: false,
				},
				{
					label: "deactivated",
					value: true,
				},
			],
			defaultValue: false,
		},
	},
	{
		key: "teams",
		label: "partOfTeam",
		componentRender: (teams) => PartOfTeam(teams),
	},
];

const LockoutStatus = (lockoutEnabled) => {
	const { translate } = useTranslations();
	return lockoutEnabled ? (
		<span className="text-red-500">{translate("deactivated")}</span>
	) : (
		<span className="text-green-500">{translate("active")}</span>
	);
};

const PartOfTeam = (teams) => {
	const { translate } = useTranslations();
	if (teams?.length === 0) return "";
	return (
		<div className="flex flex-wrap ">
			{" "}
			{teams
				?.map((team) => {
					const t_name =
						team.name.length > 12
							? team.name.substring(0, 12) + "..."
							: team.name;
					return (
						<div className="flex items-center">
							<div
								data-tooltip-variant="info"
								data-tooltip-content={
									team.name.length > 12 ? team.name : ""
								}
								data-tooltip-id={`table-tooltip`}
								className="px-3 py-1 pb-2 mt-1 w-28 justify-center text-gray-900 whitespace-nowrap
										rounded-xl flex-row flex items-center"
								style={{ backgroundColor: team.color + "80" }}
							>
								{t_name}
							</div>
						</div>
					);
				})
				.splice(0, 2)}
			{teams?.length > 2 && (
				<div className="rounded-full px-3 mt-1 py-1.5 text-center text-xs hover:opacity-60">
					{translate("viewMore")}...
				</div>
			)}
		</div>
	);
};

const UserProfile = (profiles) => {
	const { translate } = useTranslations();
	if (!profiles || profiles.length === 0) return "";

	return (
		<div className="flex flex-wrap ">
			{" "}
			{profiles
				?.map((profile) => {
					const profile_name =
						profile.name.length > 12
							? profile.name.substring(0, 12) + "..."
							: profile.name;
					return (
						<div className="flex items-center">
							<div
								data-tooltip-variant="dark"
								data-tooltip-content={
									profile.name.length > 12 ? profile.name : ""
								}
								data-tooltip-id={`table-tooltip`}
								className=" w-24 bg-gray-200 py-0.5 px-1 mt-1 justify-center text-gray-900 whitespace-nowrap
										rounded-xl flex-row flex items-center"
							>
								{profile_name}
							</div>
						</div>
					);
				})
				.splice(0, 2)}
			{profiles?.length > 2 && (
				<div className="rounded-full px-3 mt-1 py-1.5 text-center text-xs hover:opacity-60">
					{translate("viewMore")}...
				</div>
			)}
		</div>
	);
};

export default UserTable;
