import { useTranslations } from "hooks";
const ADConfigurationTable = [
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Prefix",
		},
	},
	{
		key: "userProfilesList",
		label: "userProfiles",
	},
	{
		key: "blockNormalLogin",
		label: "normalLogin",
		componentRender: (blockLogin) => NormalLogin(blockLogin),
	},
];

const NormalLogin = (blockLogin) => {
	const { translate } = useTranslations();
	return blockLogin ? (
		<span className="text-red-500">{translate("blocked")}</span>
	) : (
		<span className="text-green-500">{translate("allowed")}</span>
	);
};

export default ADConfigurationTable;
