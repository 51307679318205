import React, { useState, useEffect, forwardRef } from "react";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";

import departmentColumns from "../models/department.model";
import DepartmentService from "../services";

import DepartmentForm from "../forms/department.form";
import { useTranslations, useCrud } from "hooks";
import { CrudOperations } from "hoc";

const Tables = ({ permissions }) => {
	const { translate } = useTranslations();
	const service = new DepartmentService();
	const { create, update, getAll, remove, getExport } = useCrud(service);

	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: DepartmentForm,
		componentName: "department",
		permissions: permissions,
	});

	return (
		<Card
			className="mt-2"
			collapsible
			defaultOpen={false}
			header={"departments"}
			headerButtonLabel={
				permissions.Create && `+ ${translate("newDepartment")}`
			}
			headerButtonClick={openCreate}
		>
			<Table
				onRowClick={(row) => permissions.Update && openEdit(row)}
				onExportClick={async (queryString) => {
					return await getExport(queryString, "departments");
				}}
				columns={departmentColumns}
				isExportable={permissions.Export}
				meta={data.meta}
				data={data.data.map((item) => {
					return {
						...item,
						description: item.description
							? item.description?.slice(0, 50) +
							  (item.description?.length > 50 ? "..." : "")
							: "",
						addresses: null,
						key: item.id,
					};
				})}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			></Table>
		</Card>
	);
};

export default Tables;
