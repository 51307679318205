import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { useCrud } from 'hooks';
import { forwardRef, useImperativeHandle, useState } from 'react';
import OnlineEmployeesColumns from '../models/index';
import EmployeeService from '../services';

const Tables = forwardRef((permissions, ref) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const service = new EmployeeService();
	const { getAll } = useCrud(service);

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			setIsLoading(true);
			const res = await getAll(l_queryString || queryString);
			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	useImperativeHandle(ref, () => ({
		refresh: () => fetchAndLoad(queryString),
	}));

	return (
		<Card className='mt-2' collapsible header={'onlineEmployees'} defaultOpen={true}>
			<Table
				displayCheckBoxes={false}
				isLoading={isLoading}
				isExportable={false}
				columns={OnlineEmployeesColumns}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryStr) => {
					setQueryString(queryStr);
					fetchAndLoad(queryStr);
				}}
			/>
		</Card>
	);
});
export default Tables;
