import { AddressSelector, ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import EmployeeService from 'modules/persons/pages/Employees/services';
import TeamService from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Teams/services';
import { renderToString } from 'react-dom/server';
const TaskTable = [
	{
		key: 'id',
		label: 'I',
		componentRender: (value, row) => TaskInformationRender(value, row),
	},
	{
		key: 'scheduledDeparture',
		label: 'plannedDeparture',
		sortKey: 'ScheduledDeparture',
		filter: {
			type: 'dateRange',
			key: 'dateRangePicker',
			isParam: false,
			isArray: false,
			fromDateKey: 'fromDate',
			toDateKey: 'toDate',
			toExcludeOnApply: [
				{
					on: 'fromDate',
					exclude: 'PlannedDate',
				},
				{
					on: 'toDate',
					exclude: 'PlannedDate',
				},
			],
		},
		componentRender: (value, row) => PlannedDeparture(value, row),
	},
	{
		key: 'scheduledArrival',
		label: 'scheduled',
		sortKey: 'ScheduledArrival',
		componentRender: (value, row) => ScheduledDeparture(value, row),
	},
	{
		key: 'estimatedArrivalTime',
		label: 'ETA',
		sortKey: 'EstimatedArrivalTime',
		componentRender: (value) => EstimatedArrivalTime(value),
	},
	{
		key: 'name',
		label: 'name',
		sortKey: 'name',
		filter: {
			isParam: false,
			isArray: false,
			type: 'text',
			key: 'name',
		},
		componentRender: (value) => TruncatedString(value),
		hidden: true,
	},
	{
		key: 'status',
		label: 'state',
		sortKey: 'statusId',
		componentRender: (value) => Status(value),
	},
	{
		key: 'from',
		label: 'from',
		sortKey: 'from',
		componentRender: (value) => AddressRenderer(value),
		filter: {
			key: 'fromLocation',
			isParam: false,
			isArray: false,
			filterRender: (value, onChange) => AddressFilter(value, onChange),
		},
	},
	{
		key: 'to',
		label: 'to',
		sortKey: 'to',
		componentRender: (value) => AddressRenderer(value),
		filter: {
			key: 'toLocation',
			isParam: false,
			isArray: false,
			filterRender: (value, onChange) => AddressFilter(value, onChange),
		},
	},
	{
		key: 'employee',
		label: 'employee',
		sortKey: 'Employee',
		hidden: true,
		componentRender: (value) => EmployeeRenderer(value),
		filter: {
			key: 'employeeId',
			isParam: false,
			isArray: false,
			filterRender: (value, onChange) => EmployeeFilter(value, onChange),
		},
	},
	{
		key: 'patient',
		label: 'patient',
		sortKey: 'patient',
		filter: {
			isParam: false,
			isArray: false,
			type: 'text',
			key: 'name',
		},
		componentRender: (value) => PatientRenderer(value),
		hidden: true,
	},

	{
		key: 'taskType',
		label: 'taskType',
		sortKey: 'taskType',
		componentRender: (value) => TaskTypeRenderer(value),
		hidden: true,
	},
	{
		key: 'expectedTaskTime',
		label: 'ett',
		title: 'expectedTaskTime',
		componentRender: (value, row) => TaskExpectedTaskTimeRenderer(value, row),
		hidden: true,
	},
	{
		key: 'taskAnticipationTime',
		label: 'tat',
		title: 'taskAnticipationTime',
		componentRender: (value, row) => TaskAnticipationTimeRenderer(value, row),
		hidden: true,
	},
	{
		key: 'team',
		label: 'team',
		componentRender: (value) => TeamRenderer(value),
		filter: {
			key: 'teamId',
			isParam: false,
			isArray: false,
			filterRender: (value, onChange) => TeamFilter(value, onChange),
		},
	},

	{
		key: 'withEmployee',
		label: 'withEmployee',
		componentRender: (value) => EmployeeRenderer(value),
		filter: {
			key: 'withEmployeeId',
			isParam: false,
			isArray: false,
			filterRender: (value, onChange) => EmployeeFilter(value, onChange),
		},
		hidden: true,
	},
	{
		key: 'id',
		label: 'nr',
		sortKey: 'id',
	},
	{
		key: 'priority',
		label: 'pr',
		title: 'priority',
		sortKey: 'priority',
	},
	{
		key: 'urgency',
		label: 'urgency',
		sortKey: 'urgency',
		hidden: true,
		componentRender: (value) => urgencyRenderer(value),
	},
	{
		key: 'assignedOn',
		label: 'assignedOn',
		sortKey: 'assignedOn',
		componentRender: (value) => DateTimeRenderer(value),
		hidden: true,
	},
	{
		key: 'acceptedOn',
		label: 'acceptedOn',
		sortKey: 'acceptedOn',
		componentRender: (value) => DateTimeRenderer(value),
		hidden: true,
	},
	{
		key: 'startedOn',
		label: 'startedOn',
		sortKey: 'startedOn',
		componentRender: (value) => DateTimeRenderer(value),
		hidden: true,
	},
	{
		key: 'completedOn',
		label: 'completedOn',
		sortKey: 'completedOn',
		componentRender: (value) => DateTimeRenderer(value),
		hidden: true,
	},
	{
		key: 'cancelledOn',
		label: 'cancelledOn',
		sortKey: 'cancelledOn',
		componentRender: (value) => DateTimeRenderer(value),
		hidden: true,
	},
	{
		key: 'createdBy',
		label: 'createdBy',
		sortKey: 'createdBy',
		componentRender: (value) => CreatedByRenderer(value),
		hidden: true,
	},
	{
		key: 'createdOn',
		label: 'createdOn',
		sortKey: 'createdOn',
		componentRender: (value) => DateTimeRenderer(value),
		hidden: true,
	},
];

const PlannedDeparture = (value, row) => {
	const now = new Date();
	const date = new Date(value);

	const isToday = now.toDateString() === date.toDateString();

	const localTime = isToday
		? date.toLocaleString('en-GB', {
				hour: '2-digit',
				minute: '2-digit',
		  })
		: date.toLocaleString('en-GB', {
				day: '2-digit',
				month: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
		  });

	if (row?.scheduledTimeType?.name?.toLowerCase() === 'departure') {
		return (
			<div>
				<span className='pr-2'>D</span>
				<span>{localTime}</span>
			</div>
		);
	} else return localTime;
};

const ScheduledDeparture = (value, row) => {
	const localTime = new Date(value).toLocaleString('en-GB', {
		hour: '2-digit',
		minute: '2-digit',
	});

	if (row?.scheduledTimeType?.name?.toLowerCase() === 'arrival') {
		return (
			<div>
				<span className='pr-2'>A</span>
				<span>{localTime}</span>
			</div>
		);
	} else return localTime;
};

const DateTimeRenderer = (value) => {
	if (!value) return 'N/A';
	const localTime = new Date(value).toLocaleString('en-GB', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});

	return localTime;
};

const DateRenderer = (value) => {
	if (!value) return 'N/A';
	const localTime = new Date(value).toLocaleString('en-GB', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});

	return localTime;
};

const EstimatedArrivalTime = (value) => {
	if (!value) return 'N/A';
	const localTime = new Date(value).toLocaleString('en-GB', {
		hour: '2-digit',
		minute: '2-digit',
	});

	return localTime;
};

const Status = (value) => {
	const classes = {
		Red: {
			bg: 'bg-red-100',
			circle: 'bg-red-500',
			text: 'text-red-700',
		},
		Green: {
			bg: 'bg-green-100',
			circle: 'bg-green-500',
			text: 'text-green-700',
		},
		Orange: {
			bg: 'bg-yellow-100',
			circle: 'bg-yellow-500',
			text: 'text-yellow-700',
		},
		Gray: {
			bg: 'bg-gray-100',
			circle: 'bg-gray-500',
			text: 'text-gray-700',
		},
		Lila: {
			bg: 'bg-purple-100',
			circle: 'bg-purple-500',
			text: 'text-purple-700',
		},
		LightBlue: {
			bg: 'bg-cyan-100',
			circle: 'bg-cyan-500',
			text: 'text-cyan-700',
		},
		Blue: {
			bg: 'bg-blue-100',
			circle: 'bg-blue-500',
			text: 'text-blue-700',
		},
		LightGreen: {
			bg: 'bg-lime-100',
			circle: 'bg-lime-500',
			text: 'text-lime-700',
		},
	};
	const statusClass = classes[value.color] || classes.Gray;

	return (
		<div className='flex items-center'>
			<div className={`${statusClass.bg} px-3 py-1 rounded-xl flex-row flex items-center`}>
				<div className={`inline-block ${statusClass.circle} rounded-full h-3 w-3 mr-2`}></div>
				<div className={`uppercase ${statusClass.text}`}>{value.name}</div>
			</div>
		</div>
	);
};

const AddressRenderer = (value) => {
	// the address is a nested object and we have to display the previous than the last child and the list child like a breadcrumb

	let address = value?.child;
	const addressList = [];
	while (address) {
		addressList.push(address);
		address = address?.child;
	}

	//display only last 2 address like a breadcrumb
	if (addressList.length > 2) {
		const temp = addressList.slice(addressList.length - 2).filter((a) => a.level < 3);
		addressList.length = 0;
		addressList.push(
			// eslint-disable-next-line no-undef
			...temp,
		);
	}

	return (
		<div>
			{addressList.map((address, index) => (
				<div key={index} className='flex items-center'>
					<span className='text-gray-500 whitespace-nowrap '>{address.name}</span>
					{index !== addressList.length - 1 && <span className='text-gray-500 mx-2'>/</span>}
				</div>
			))}
		</div>
	);
};

const EmployeeRenderer = (value) => {
	if (!value) return 'N/A';
	return `${value?.name}`;
};

const TeamRenderer = (value) => {
	if (!value || !value.name) return null;
	const t_name = value.name.length > 12 ? value.name.substring(0, 12) + '...' : value.name;
	return (
		<div className='flex items-center'>
			<div
				data-tooltip-variant='info'
				data-tooltip-content={value.name.length > 12 ? value.name : ''}
				data-tooltip-id={`table-tooltip`}
				className='px-3 py-1 pb-2 w-28 justify-center text-gray-900 whitespace-nowrap
				rounded-xl flex-row flex items-center'
				style={{ backgroundColor: value.color + '80' }}
			>
				{t_name}
			</div>
		</div>
	);
};

const PatientRenderer = (value) => {
	const { translate } = useTranslations();

	if (!value) return 'N/A';

	const tooltipHtml = (
		<div className='p-2'>
			<table className='table-auto text-left'>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('admissionNumber')}
						{': '}
					</th>
					<td>{value.admissionNumber}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('patientNumber')}
						{': '}
					</th>
					<td>{value.patientNumber}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('name')}
						{': '}
					</th>
					<td>{value.name}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('gender')}
						{': '}
					</th>
					<td>{value.gender}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('dateOfBirth')}
						{': '}
					</th>
					<td>{DateRenderer(value.dateOfBirth)}</td>
				</tr>
			</table>
		</div>
	);

	return (
		<div
			className='px-3 py-1.5 bg-gray-300 rounded-3xl'
			data-tooltip-variant='light'
			data-tooltip-delay-hide={300}
			data-tooltip-html={renderToString(tooltipHtml)}
			data-tooltip-id={`table-tooltip`}
		>
			<div className='pl-1 flex justify-between space-x-5 flex-row items-center '>
				<div className=''>
					<div className='whitespace-nowrap font-light  text-xs text-gray-500'>{value?.patientNumber}</div>

					<div className='text-gray-700  font-medium whitespace-nowrap '>{value?.name}</div>
				</div>
				<div>
					<div className='text-gray-700 font-bold'>{value?.gender?.charAt(0).toUpperCase()}</div>
				</div>
			</div>
		</div>
	);
};

const TaskTypeRenderer = (value) => {
	return value?.name;
};

const TaskExpectedTaskTimeRenderer = (value, row) => {
	return row?.taskType?.expectedTaskTime;
};

const TaskAnticipationTimeRenderer = (value, row) => {
	return row?.taskType?.anticipationTime;
};

const CreatedByRenderer = (value, includeUsername = false) => {
	if (!value) return 'N/A';

	if (!value.name && value.userName) {
		return value.userName;
	}

	if (includeUsername && value?.userName) {
		return `${value?.name} (${value?.userName})`;
	}

	return value?.name;
};

const urgencyRenderer = (value) => {
	return value?.name;
};

const TruncatedString = (value, truncateAfter = 12) => {
	let truncatedString = value;
	if (value?.length > truncateAfter) {
		truncatedString = value.substring(0, truncateAfter) + '...';
	}
	return (
		<div
			data-tooltip-variant='info'
			data-tooltip-content={value?.length > truncateAfter ? value : ''}
			data-tooltip-id={`table-tooltip`}
		>
			{truncatedString}
		</div>
	);
};

const AddressFilter = (value, onChange) => {
	return (
		<AddressSelector
			containerClassName='w-full py-1 px-1 text-sm capitalize '
			truncateAt={10}
			defaultValue={value}
			onChange={onChange}
		/>
	);
};

const TeamFilter = (value, onChange) => {
	const service = new TeamService();

	return (
		<ReSelect
			small
			containerClassName='w-36 text-sm capitalize '
			service={service}
			onChange={onChange}
			defaultValue={value}
		/>
	);
};

const EmployeeFilter = (value, onChange) => {
	const service = new EmployeeService();

	return (
		<div className='capitalize w-36'>
			<ReSelect
				containerClassName='w-36 text-sm capitalize '
				small
				service={service}
				onChange={onChange}
				defaultValue={value}
			/>
		</div>
	);
};

const TaskInformationRender = (value, row) => {
	const { translate } = useTranslations();

	const tooltipHtml = (
		<div className='p-2'>
			<table className='table-auto text-left'>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('taskNumber')}
						{': '}
					</th>
					<td>{row.id}</td>
				</tr>
				<tr className='pb-1'>
					<th className='pr-2'>
						{translate('createdOn')}
						{': '}
					</th>
					<td>
						{DateTimeRenderer(row.createdOn)}
						<span className='pl-2 text-xs'>{CreatedByRenderer(row.createdBy, true)}</span>
					</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('scheduleTime')}
						{': '}
					</th>
					<td className='pl-1'>{ScheduledDeparture(row?.scheduledArrival, row)}</td>
				</tr>
				<tr className='pb-1'>
					<th className='pr-2'>
						{translate('plannedDeparture')}
						{': '}
					</th>
					<td className='pl-1'>{PlannedDeparture(row?.scheduledDeparture, row)}</td>
				</tr>
				{row.startedOn && (
					<tr className='pb-1'>
						<th className=' pr-2'>
							{translate('startedOn')}
							{': '}
						</th>
						<td>{DateTimeRenderer(row.startedOn)}</td>
					</tr>
				)}

				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('dispatchingMethod')}
						{': '}
					</th>
					<td>{translate(row?.taskExtraDetails?.dispatchingMethod?.name)}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('team')}
						{': '}
					</th>
					<td>
						<div
							style={{
								backgroundColor: row?.team?.color,
							}}
							className={`inline-block  rounded-full h-3 w-3 mr-2`}
						></div>
						{row?.team?.name}
					</td>
				</tr>
				<tr className='pb-3'>
					<th className='pr-2'>
						{translate('taskTemplate')}
						{': '}
					</th>
					<td>
						<div>
							<div
								style={{
									backgroundColor: row?.taskTemplate?.color,
								}}
								className={`inline-block  rounded-full h-3 w-3 mr-2`}
							></div>
							{row?.taskTemplate?.name}
						</div>
					</td>
				</tr>
			</table>
			{row?.taskExtraDetails?.taskActionModels?.length > 0 && (
				<div className='pb-3 pt-3'>
					<div className='text-lg text-center opacity-60 font-bold  '>{translate('taskActions')}</div>
					<div className='border-b-2 border-gray-200  mb-2'></div>

					{row?.taskExtraDetails?.taskActionModels?.map((action) => (
						<div className='pb-2 text-xs'>
							<div className='text-xs text-primary-600 font-bold'>{translate(action?.action?.name)}</div>
							<table className='table-auto pt-1 text-left'>
								{action?.performedOn && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('performedOn')}
											{': '}
										</th>
										<td>{DateTimeRenderer(action?.performedOn)}</td>
									</tr>
								)}
								{action?.performedBy && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('performedBy')}
											{': '}
										</th>
										<td>{action?.performedBy?.name}</td>
									</tr>
								)}
								{action?.blockedUntil && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('blockedUntil')}
											{': '}
										</th>
										<td>{DateTimeRenderer(action?.blockedUntil)}</td>
									</tr>
								)}
								{action?.complexTaskActionName && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('complexTaskActionName')}
											{': '}
										</th>
										<td>{translate(action.complexTaskActionName)}</td>
									</tr>
								)}
							</table>
						</div>
					))}
				</div>
			)}
		</div>
	);

	return (
		<div
			data-tooltip-variant='light'
			data-tooltip-id={`table-tooltip`}
			data-tooltip-delay-hide={300}
			data-tooltip-html={renderToString(tooltipHtml)}
			className='text-primary-500 hover:opacity-75'
		>
			<i class='ri-information-line'></i>
		</div>
	);
};

export default TaskTable;
