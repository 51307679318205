import React, { useState, useEffect } from "react";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";
import { useTranslations, useCrud } from "hooks";
import CustomFieldService from "../services";
import { CrudOperations } from "hoc";
import CustomFieldColumns from "../models/CustomField.model";
import CustomFieldForm from "../forms/CustomField.form";

const Tables = ({ permissions }) => {
	const service = new CustomFieldService();

	const { create, update, getAll, remove } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: CustomFieldForm,
		componentName: "customField",
		permissions: permissions,
	});

	return (
		<Card
			className="mt-2"
			collapsible
			header={"customFields"}
			subHeader={"configureExtraCustomFieldsToBeUsedInTaskTemplates"}
			defaultOpen={true}
			headerButtonClick={openCreate}
			headerButtonLabel={
				permissions.Create && `+ ${translate("addCustomField")}`
			}
		>
			<Table
				isExportable={false}
				columns={CustomFieldColumns}
				meta={data?.meta}
				data={data?.data.map((item) => {
					return { ...item };
				})}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
				onRowClick={(row) => permissions.Update && openEdit(row)}
			/>
		</Card>
	);
};

export default Tables;
