import component from "modules/addresses/component";

const TeamColumns = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
	},
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Prefix",
		},
		componentRender: (value, row) => TeamRenderer(value, row),
	},
	{
		key: "openTime",
		label: "openTime",
		sortKey: "openTime",
	},
	{
		key: "openReadyTasksCount",
		label: "openReadyTasks",
		sortKey: "openReadyTasksCount",
	},
	{
		key: "completedTasksCount",
		label: "completedTasks",
		sortKey: "completedTasksCount",
	},
	{
		key: "cancelledTasksCount",
		label: "cancelledTasks",
		sortKey: "cancelledTasksCount",
	},
	{
		key: "inProgressTasksCount",
		label: "inProgressTasks",
		sortKey: "inProgressTasksCount",
	},
	{
		key: "openNotReadyTasksCount",
		label: "openNotReadyTasks",
		sortKey: "openNotReadyTasksCount",
	},
	{
		key: "loggedInEmployeesCount",
		label: "loggedInEmployees",
		sortKey: "loggedInEmployeesCount",
	},
];

const TeamRenderer = (value, row) => {
	if (!value || !value) return null;
	const t_name = value.length > 12 ? value.substring(0, 12) + "..." : value;
	return (
		<div className="flex items-center">
			<div
				data-tooltip-variant="info"
				data-tooltip-content={value.length > 12 ? value : ""}
				data-tooltip-id={`table-tooltip`}
				className="px-3 py-1 pb-2 w-28 justify-center text-gray-900
				rounded-xl flex-row flex items-center"
				style={{ backgroundColor: row.color + "80" }}
			>
				{t_name}
			</div>
		</div>
	);
};

export default TeamColumns;
