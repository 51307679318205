const TaskColumns = [
	{
		key: "id",
		label: "id",
		sortKey: "id",
	},
	{
		key: "fullName",
		label: "fullName",
		sortKey: "fullName",
		filter: {
			type: "text",
			key: "FullName",
		},
	},
	{
		key: "patientNr",
		label: "patientNr",
		sortKey: "patientNr",
		filter: {
			type: "text",
			key: "PatientNr",
		},
	},
	{
		key: "admissionNr",
		label: "admissionNr",
		sortKey: "admissionNr",
		filter: {
			type: "text",
			key: "AdmissionNr",
		},
	},
	{
		key: "homeLocation",
		label: "homeLocation",
		sortKey: "homeLocation",
		componentRender: (value) => AddressRenderer(value),
	},
	{
		key: "gender",
		label: "gender",
		sortKey: "gender",
		componentRender: (value) => GenderRenderer(value),
	},
	{
		key: "status",
		label: "status",
		sortKey: "status",
		filter: {
			type: "select",
			key: "Status",
			options: [
				{
					label: "all",
					value: "0",
				},
				{
					label: "PreAdmitted",
					value: "1",
				},
				{
					label: "Admitted",
					value: "2",
				},
				{
					label: "Discharged",
					value: "3",
				},
				{
					label: "TemporarilyLeave",
					value: "4",
				},
			],
		},

		componentRender: (value) => Status(value),
	},
	{
		key: "updatedOn",
		label: "updatedOn",
		sortKey: "updatedOn",

		componentRender: (value) => DateTimeRenderer(value),
	},
	{
		key: "eventDateTime",
		label: "eventDateTime",
		sortKey: "eventDateTime",
		filter: {
			type: "date",
			key: "EventDateTime",
		},

		componentRender: (value) => DateTimeRenderer(value),
	},
];
export default TaskColumns;

const DateTimeRenderer = (value) => {
	if (!value) return "N/A";
	const localTime = new Date(value).toLocaleString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	});

	return localTime;
};

const Status = (value) => {
	if (!value || !value?.name) return "N/A";
	const classes = [
		{
			bg: "bg-cyan-100",
			circle: "bg-cyan-500",
			text: "text-cyan-700",
		},
		{
			bg: "bg-red-100",
			circle: "bg-red-500",
			text: "text-red-700",
		},
		{
			bg: "bg-green-100",
			circle: "bg-green-500",
			text: "text-green-700",
		},
		{
			bg: "bg-purple-100",
			circle: "bg-purple-500",
			text: "text-purple-700",
		},
	];

	const statusClass = classes[value.id] || classes[0];

	return (
		<div className="flex items-center">
			<div
				className={`${statusClass.bg} px-3 py-1 rounded-xl flex-row flex items-center`}
			>
				<div
					className={`inline-block ${statusClass.circle} rounded-full h-3 w-3 mr-2`}
				></div>
				<div className={`uppercase ${statusClass.text}`}>
					{value.name}
				</div>
			</div>
		</div>
	);
};

const GenderRenderer = (value) => {
	if (!value || !value?.name) return "N/A";

	return value.name;
};

const AddressRenderer = (value, displayLastTwo = false) => {
	// the address is a nested object and we have to display the previous than the last child and the list child like a breadcrumb

	let address = value?.child;
	const addressList = [];
	while (address) {
		addressList.push(address);
		address = address?.child;
	}

	//display only last 2 address like a breadcrumb
	if (displayLastTwo && addressList.length > 2) {
		const temp = addressList.slice(addressList.length - 2);
		addressList.length = 0;
		addressList.push(
			// eslint-disable-next-line no-undef
			...temp
		);
	}

	return (
		<div>
			{addressList.map((address, index) => (
				<div key={index} className="flex items-center">
					<span className="text-gray-500">{address.name}</span>
					{index !== addressList.length - 1 && (
						<span className="text-gray-500 mx-2">/</span>
					)}
				</div>
			))}
		</div>
	);
};
