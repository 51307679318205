import React, { useState, useEffect } from "react";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";
import { useTranslations, useCrud } from "hooks";
import userProfileColumns from "../models/userProfile.model";
import { runAction } from "modules/utils";

import UserProfileForm from "../forms/userProfile.form";
import UserProfileService from "../services";
import { CrudOperations } from "hoc";

const Tables = ({ permissions }) => {
	const service = new UserProfileService();
	const { create, update, getAll, remove, getExport } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState({ data: [], meta: {} });

	const [viewPatientsLevelEnum, setViewPatientsLevelEnum] = useState({});
	const [queryString, setQueryString] = useState("");

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: UserProfileForm,
		componentName: "userProfile",
		permissions: permissions,
		formWidth: 54,
	});

	const getViewPatientsLevelEnum = async () => {
		const l_viewPatientsLevelEnum = await runAction(
			"tenants",
			"getEnum",
			"ViewPatientsLevel"
		);
		setViewPatientsLevelEnum(l_viewPatientsLevelEnum);
		return l_viewPatientsLevelEnum;
	};

	useEffect(() => {
		const handleHashChange = async () => {
			if (window.location.hash === "#newUserProfile") {
				const l_viewPatientsLevelEnum =
					await getViewPatientsLevelEnum();

				openCreate({
					viewPatientsLevelEnum: l_viewPatientsLevelEnum,
				});
			}
		};

		window.addEventListener("hashchange", handleHashChange);
		handleHashChange();
		return () => {
			window.removeEventListener("hashchange", handleHashChange);
		};
	}, []);

	useEffect(() => {
		getViewPatientsLevelEnum().then(() => {
			fetchAndLoad();
		});
	}, []);

	return (
		<Card
			className="mt-2"
			collapsible
			defaultOpen={false}
			header={"userProfiles"}
			headerButtonLabel={
				permissions.Create && `+ ${translate("newUserProfile")}`
			}
			headerButtonClick={() => {
				openCreate({ viewPatientsLevelEnum });
			}}
		>
			<Table
				isExportable={permissions.Export}
				columnsCanBeModified={false}
				columns={userProfileColumns}
				meta={data.meta}
				fetchData={fetchAndLoad}
				onRowClick={(row) =>
					permissions.Update &&
					openEdit(row, { viewPatientsLevelEnum })
				}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
				onExportClick={async (queryString) => {
					return await getExport(queryString, "user-profiles");
				}}
				data={data.data?.map((f) => ({
					...f,
					departmentsList:
						f.departments
							.slice(0, 3)
							.map((d) => d.name)
							.join(", ") +
						(f.departments.length > 3 ? ", ..." : ""),
					permissionGroupList:
						f.permissionGroups
							.slice(0, 2)
							.map((d) => d.name)
							.join(", ") +
						(f.permissionGroups.length > 2 ? ", ..." : ""),
					taskGroupList:
						f.taskGroups
							.slice(0, 3)
							.map((d) => d.name)
							.join(", ") +
						(f.taskGroups.length > 3 ? ", ..." : ""),
					viewPatientsLevelName: Object.keys(viewPatientsLevelEnum)
						.find(
							(key) =>
								viewPatientsLevelEnum[key] ===
								f.viewPatientsLevel
						)
						?.replace(/([A-Z])/g, " $1")
						.trim(),
				}))}
			/>
		</Card>
	);
};
export default Tables;
