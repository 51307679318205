const UserProfileTable = [
	{
		key: "name",
		label: "userProfile",
		sortKey: "name",
		filter: {
			type: "text",
			key: "Name",
		},
	},
	{
		key: "departmentsList",
		label: "departments",
	},
	{
		key: "permissionGroupList",
		label: "permissionGroups",
	},
	{
		key: "viewPatientsLevelName",
		label: "showPatients",
		sortKey: "viewPatientsLevel",
	},
	{
		key: "taskGroupList",
		label: "taskGroups",
	},
];
export default UserProfileTable;
