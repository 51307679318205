import React, {
	useEffect,
	useRef,
	useReducer,
	useImperativeHandle,
	forwardRef,
} from "react";

function clampIndex(index) {
	if (index > 6) {
		return 6;
	} else if (index < 0) {
		return 0;
	} else {
		return index;
	}
}

function reducer(state, action) {
	switch (action.type) {
		case "INPUT":
			return {
				...state,
				inputValues: [
					...state.inputValues.slice(0, action.payload.index),
					action.payload.value,
					...state.inputValues.slice(action.payload.index + 1),
				],
				focusedIndex: clampIndex(state.focusedIndex + 1),
			};

		case "BACK":
			return {
				...state,
				focusedIndex: clampIndex(state.focusedIndex - 1),
			};

		case "PASTE":
			return {
				...state,
				inputValues: state.inputValues.map(
					(_, index) => action.payload.pastedValue[index] || ""
				),
			};

		case "FOCUS":
			return {
				...state,
				focusedIndex: action.payload.focusedIndex,
			};

		case "VERIFY":
			return {
				...state,
				status: "pending",
			};

		case "VERIFY_SUCCESS":
			return {
				...state,
				status: "idle",
			};

		default:
			throw new Error("unknown action");
	}
}

const initialState = {
	inputValues: Array(6).fill(""),
	focusedIndex: 0,
	status: "idle",
};

function Input({
	index,
	value,
	onChange,
	onPaste,
	onBackspace,
	isFocused,
	onFocus,
	isDisabled,
}) {
	const ref = useRef();
	useEffect(() => {
		requestAnimationFrame(() => {
			if (ref.current !== document.activeElement && isFocused) {
				ref.current.focus();
			}
		});
	}, [isFocused]);

	function handleChange(e) {
		onChange(index, e.target.value);
	}

	function handlePaste(e) {
		onPaste(e.clipboardData.getData("text"));
	}

	function handleKeyDown(e) {
		if (e.key === "Backspace") {
			onBackspace();
		}
	}

	function handleFocus(e) {
		e.target.setSelectionRange(0, 1);
		onFocus(index);
	}

	return (
		<input
			ref={ref}
			type="text"
			value={value}
			onChange={handleChange}
			onPaste={handlePaste}
			onKeyDown={handleKeyDown}
			maxLength="1"
			onFocus={handleFocus}
			disabled={isDisabled}
			className={`bg-gray-50 border text-3xl text-center border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1`}
		/>
	);
}

const CodeInput = forwardRef(({ onChange = () => {} }, ref) => {
	const [{ inputValues, focusedIndex, status }, dispatch] = useReducer(
		reducer,
		initialState
	);

	function handleInput(index, value) {
		dispatch({ type: "INPUT", payload: { index, value } });
	}

	function handleBack() {
		dispatch({ type: "BACK" });
	}

	function handlePaste(pastedValue) {
		dispatch({ type: "PASTE", payload: { pastedValue } });

		if (pastedValue.length === 6) {
			// doSubmit(pastedValue.split(""))
			// dispatch({ type: "VERIFY" });
			// doSubmit(pastedValue.split("")).then(() =>
			// 	dispatch({ type: "VERIFY_SUCCESS" })
			// );
		}
	}

	function handleFocus(focusedIndex) {
		dispatch({ type: "FOCUS", payload: { focusedIndex } });
	}

	useImperativeHandle(ref, () => ({
		setCode: (code) => handlePaste(code),
		clear: () => handlePaste(""),
	}));

	useEffect(() => {
		onChange(inputValues.join(""));
	}, [inputValues, onChange]);

	return (
		<div className="w-full grid grid-cols-6 gap-0.5">
			{inputValues.map((value, index) => {
				return (
					<Input
						key={index}
						index={index}
						value={value}
						onChange={handleInput}
						onBackspace={handleBack}
						onPaste={handlePaste}
						isFocused={index === focusedIndex}
						onFocus={handleFocus}
						isDisabled={status === "pending"}
					/>
				);
			})}
		</div>
	);
});

CodeInput.defaultProps = {
	className: "",
};

export default CodeInput;
